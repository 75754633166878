<template>
  <v-container
    relative
    class="home-wrapper pa-0"
    fluid
  >
    <Navbar />
    <transition name="slide-fade">
      <SocialToolbar v-if="showFooter" />
    </transition>
    <vue-particles
      color="#ffffff"
      :particleOpacity="0.7"
      linesColor="#ffffff"
      :particlesNumber="40"
      shapeType="circle"
      :particleSize="5"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    />
  </v-container>
</template>

<script>
import { SocialToolbar } from '@/components/common'
export default {
  components: {
    SocialToolbar
  },
  mounted () {
    this.$nextTick(() => {
      this.showFooter = true
    })
  },
  data () {
    return {
      showFooter: false
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1.8s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/* mobile */
.home-wrapper {
  background-color: black; /* fallback color */
  /* position: absolute;
  top: 0;*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* mobile */
#particles-js {
  width: 100%;
  height: 82vh;
}

/* Desktop */
@media (min-width: 768px) {
  .home-wrapper {
    height: 100vh;
  }

  #particles-js {
    height: 100vh;
  }
}
</style>
