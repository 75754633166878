<template>
    <v-footer
      absolute
      fixed
      class="transparent justify-center"
    >
      <a class="pa-2 pl-8 pr-8" href="mailto:lars@softwarefun.no">
        <v-icon
          color="white"
          medium
          class="icon"
        >
          mdi-email
        </v-icon>
      </a>
      <a class="pa-2 pl-8 pr-8" href="https://www.linkedin.com/in/lars-martin-hejll-a7788875">
        <v-icon
          color="white"
          medium
          class="icon"
        >
          mdi-linkedin
        </v-icon>
      </a>
      <a class="pa-2 pl-8 pr-8" href="https://twitter.com/larsmarslars">
        <v-icon
          color="white"
          medium
          class="icon"
        >
          mdi-twitter
        </v-icon>
      </a>
      <a class="pa-2 pl-8 pr-8" href="https://github.com/larsmars">
        <v-icon
          color="white"
          medium
          class="icon"
        >
          mdi-github
        </v-icon>
      </a>
    </v-footer>
</template>

<script>
export default {
  name: 'SocialFooter'
}
</script>

<style scoped>
.icon:hover {
  opacity: .6;
}
</style>
