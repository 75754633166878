<template>
    <v-toolbar
      absolute
      dense
      vertical
      class="pa-0 transparent toolbar"
    >
    <!--
      <a class="pl-1" href="mailto:lars@softwarefun.no">
        <v-icon
          class="icon"
          medium
          color="white"
        >
          mdi-email
        </v-icon>
      </a>
      -->
      <a class="pl-1" href="https://www.linkedin.com/in/lars-martin-hejll-a7788875">
        <v-icon
          class="icon"
          medium
          color="white"
        >
          mdi-linkedin
        </v-icon>
      </a>
      <a class="pl-1" href="https://twitter.com/larsmarslars">
        <v-icon
          class="icon"
          medium
          color="white"
        >
          mdi-twitter
        </v-icon>
      </a>
      <a class="pl-1" href="https://github.com/larsmars">
        <v-icon
          class="icon"
          medium
          color="white"
        >
          mdi-github
        </v-icon>
      </a>
    </v-toolbar>
</template>

<script>
export default {
  name: 'SocialFooter'
}
</script>

<style scoped>
.icon:hover {
  opacity: .6;
}

.toolbar {
  right: 0px;
}
</style>
