import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
  {
    path: '/progressbar',
    name: 'Progressbar',
    component: () => import(/* webpackChunkName: "ProgressBar" */ '../views/projects/ProgressBar.vue')
  },
  {
    path: '/toggleswitch',
    name: 'ToggleSwitch',
    component: () => import(/* webpackChunkName: "ToggleSwitch" */ '../views/projects/ToggleSwitch.vue')
  },
  {
    path: '/thermometer',
    name: 'Thermometer',
    component: () => import(/* webpackChunkName: "Thermometer" */ '../views/projects/Thermometer.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
