<template>
    <v-app-bar
      absolute
      dense
      flat
      class="pa-0 transparent"
    >
      <v-app-bar-nav-icon
        color="white"
        v-show="!drawer"
        @click.stop="drawer = !drawer"
      />
      <v-navigation-drawer
        v-model="drawer"
        absolute
        class="nav-drawer black"
      >
        <v-list
          class="pt-0"
          nav
        >
          <v-list-item
            link
            :to="'/'"
            :ripple="false"
          >
            <v-list-item-icon>
              <v-icon
                color="white"
                class="mr-0"
              >
                mdi-home
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            link
            :to="'/about'"
            :ripple="false"
          >
            <v-list-item-icon>
              <v-icon
                color="white"
                class="mr-0"
              >
                mdi-account-circle
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      drawer: false
    }
  }
}
</script>

<style scoped>
.nav-drawer {
  height: 30vh !important;
  max-width: 50px !important;
}
</style>
